@import "./variables.scss";
@import "./styleguides.scss";
@import "./fonts.scss";

.hero {
  height: 100svh;
  width: 100%;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 1;
    background: linear-gradient(0deg, $background, transparent);

    height: 100px;
  }
}

.hero-bg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: -1;
}

.hero-content {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 3rem;

  .text-cta {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 3rem;
    // max-width: 30vw;
  }
  .info-images {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40vh;
    @media (width <= map-get($grid-breakpoints, lg)) {
      height: 15vh;
    }

    img {
      height: 100%;
    }
  }

  .hero-info {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 3rem;
    &__text {
      font-size: 21px;
      font-weight: 500;
      text-align: center;
    }
  }

  @media (width <= map-get($grid-breakpoints, lg)) {
    padding-top: calc($header-height + 2rem);
    flex-direction: column;
    justify-content: flex-start;
  }
}

.hero-headline {
  width: clamp(200px, 30vw, 400px);

  // @media (width <= map-get($grid-breakpoints, lg)) {
  // }
}

.hero-subheadline {
  font-size: 48px;
  font-weight: 600;
  text-align: center;
}

.financial-section {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  overflow: hidden;

  @media (width <= map-get($grid-breakpoints, lg)) {
    flex-direction: column;
  }

  &__box {
    border: 3px solid $primary;
    border-radius: 20px;
    padding: 2rem;
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    width: clamp(100px, 30vw, 200px);
    position: relative;

    &:first-of-type::before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(-100%, -50%);
      border-radius: 300px;
      z-index: -1;
      background: linear-gradient(-90deg, $primary-gradient-end 11.12%, $primary-gradient-start 60%, transparent 100%);

      height: 3px;
      width: 100%;
    }

    &:last-of-type::after {
      content: "";
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(100%, -50%);
      border-radius: 300px;
      z-index: -1;
      background: linear-gradient(90deg, $primary-gradient-end 11.12%, $primary-gradient-start 60%, transparent 100%);

      height: 3px;
      width: 100%;
    }
  }

  &__text {
    font-size: 29px;
    font-weight: 500;
  }

  &__main-number {
    font-size: 48px;
    font-weight: 900;
    background: linear-gradient(
      0deg,
      $primary-gradient-start 12.12%,
      $primary-gradient-mid 42.19%,
      $primary-gradient-end 104.49%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    &::selection {
      -webkit-text-fill-color: black;
    }
  }
}

// #explore {
//   font-size: 40px;
//   font-weight: 600;
// }

.explore-phases-title {
  font-size: 40px;
  font-weight: 600;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.live-badge {
  padding: 0.5rem 1rem;
  background-color: #003307;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 600;

  padding-left: 2rem;
  position: relative;
  &::after {
    content: url("./../assets/icons/live-dot.svg");
    position: absolute;
    width: 20px;
    height: 100%;
    left: 1rem;
    top: 50%;
    transform: translate(-50%, -25%);
    color: inherit;
    z-index: 1;
  }
}

.stage-container {
  border: 1px solid #3f3f3f;
  border-radius: 27px;
  width: 100%;
  padding: 2rem 3rem;
  box-sizing: border-box;
  position: relative;

  .stage-title {
    font-family: "Orbitron", sans-serif;
    font-size: 45px;
    font-weight: 600;

    background: linear-gradient(0deg, #7ef38f, $white);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    &::selection {
      -webkit-text-fill-color: black;
    }
  }

  .stage {
    width: 100%;
    display: flex;
    .left-side {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 3rem;
      width: 70%;
      @media (width <= map-get($grid-breakpoints, lg)) {
        width: 100%;
      }
    }

    .right-side {
      width: 30%;
      img {
        width: 100%;
      }
      @media (width <= map-get($grid-breakpoints, lg)) {
        width: 100%;
      }
    }

    @media (width <= map-get($grid-breakpoints, lg)) {
      flex-direction: column;
    }

    &__subtitle {
      font-size: 35px;
      font-weight: 600;
    }

    &__content {
      font-size: 23px;
      font-weight: 500;
    }

    &__selling-points-container {
      display: flex;
      justify-content: flex-start;
      gap: 10rem;
      @media (width <= map-get($grid-breakpoints, lg)) {
        flex-direction: column;
        gap: 2rem;
      }
    }

    &__selling-points {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }

    &__selling-point {
      padding: 1rem 3rem;
      position: relative;
      box-sizing: border-box;

      &::after {
        content: url("./../assets/icons/checkmark.svg");
        position: absolute;
        width: 1rem;
        height: 100%;
        left: 0;
        color: inherit;
      }

      &.purple-point {
        color: $purple;
        &::after {
          content: url("./../assets/icons/purple-checkmark.svg");
        }
      }
      &.green-point {
        color: $primary;
        &::after {
          content: url("./../assets/icons/green-checkmark.svg");
        }
      }
      &.brown-point {
        color: $brown;
        &::after {
          content: url("./../assets/icons/brown-checkmark.svg");
        }
      }
    }
  }
  .stage-4-image {
    img {
      position: absolute;
      bottom: 0;
      right: 0;
      height: 100%;
      width: auto;
      object-fit: contain;
      object-position: right;
      margin-right: -3rem;
    }

    @media (width <= map-get($grid-breakpoints, lg)) {
      height: 100%;
      position: relative;
      img {
        position: relative;
        margin-bottom: -2rem;
        object-position: bottom;
        width: 100%;
        height: auto;
      }
    }
  }
}

.stage-parts {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: stretch;
  gap: 3rem;

  @media (width <= map-get($grid-breakpoints, lg)) {
    flex-direction: column;
  }
}

.stage-part {
  box-sizing: border-box;
  border: 1px solid $primary;
  border-radius: 26px;
  padding: 3rem 2rem;
  position: relative;
  line-height: 130%;

  &__title {
    font-size: 24px;
    font-weight: 600;
  }
  &__content {
    font-size: 18px;
    font-weight: 500;
  }
  &__bg-image {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
    height: 100%;
    width: auto;
    max-width: 100%;
    object-fit: contain;
  }
  &__button {
    width: 300px;
    max-width: 100%;
    transform-origin: top left;
    .action-button {
      padding: 1rem 2rem;
      font-size: 15px;
    }
  }

  &.left-side {
    width: 30%;
  }

  &.right-side {
    width: 70%;
  }

  @media (width <= map-get($grid-breakpoints, lg)) {
    &.left-side {
      width: 100%;
    }

    &.right-side {
      width: 100%;
    }
  }
}

.highlighted-text {
  padding: 1rem;
  background-color: #003307;
  border-radius: 8px;
}

.explore-participants-title {
  font-size: 35px;
  font-weight: 600;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding-left: clamp(0rem, 6vw, 8rem);
  padding-right: clamp(0rem, 6vw, 8rem);
}

.soon-badge {
  padding: 0.5rem 1rem;
  background-color: #003307;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 600;
  color: $primary;
  height: fit-content;

  position: relative;
}

.participants-container {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  flex-wrap: wrap;
  @media (width <= map-get($grid-breakpoints, xxl)) {
    justify-content: space-around;
  }
}

.participant-item {
  border: 1px solid #191919;
  border-radius: 18px;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem;
  // height: 100%;
  width: 18%;
  min-width: 220px;

  &__icon {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    img {
      height: 40px;
      width: auto;
      object-fit: contain;
    }
  }

  &__title {
    font-size: 23px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__description {
    font-size: 15px;
  }
}
