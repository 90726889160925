@import "./../../styles/variables.scss";

.footer-container {
  width: 100%;
  padding: 3rem;
  box-sizing: border-box;
  z-index: 999;

  @media (width <= map-get($grid-breakpoints, lg)) {
    padding: 1rem;
  }
}

.footer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  &__left-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 2rem;
  }

  &__right-section {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 2rem;
  }

  &__social {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }

  &__social-icon {
    img {
      height: 43px;
      width: auto;
    }
  }

  &__text {
    font-size: 20px;
    font-weight: 600px;
  }

  @media (width <= map-get($grid-breakpoints, lg)) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4rem;

    &__left-section,
    &__right-section,
    .footer-menu {
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }
}

.navbar-link {
  font-size: 16px;
  font-weight: 500;
  color: $white;

  &:hover {
    color: $primary;
  }
}

.footer-section {
  width: 33%;
  @media (width <= map-get($grid-breakpoints, lg)) {
    width: auto;
  }
}

.footer-menu {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 1rem;
}

.footer-divider-line {
  width: 100%;
  box-sizing: border-box;
  border-top: 1px solid rgba($white, 50%);
}

.footer-legal-section {
  display: flex;
  justify-content: space-between;
  gap: 2rem;

  @media (width <= map-get($grid-breakpoints, lg)) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .left-side {
    width: 50%;
    display: flex;
    justify-content: flex-start;
    gap: 2rem;
    @media (width <= map-get($grid-breakpoints, lg)) {
      width: auto;
    }
  }

  .right-side {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    gap: 2rem;
    text-align: right;
    @media (width <= map-get($grid-breakpoints, lg)) {
      width: auto;
    }
  }
}
