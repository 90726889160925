@use "sass:meta";

$primary: #00e020;
$secondary: #00e020;
$accent: #32ff15;
$brown: #f7931a;
$purple: #8639f5;

$primary-gradient-start: #2c9412;
$primary-gradient-mid: #4cb531;
$primary-gradient-end: #38ff04;

$default-text-color: #ffffff;
$background: #000000;

$gray: #303030;
$dark: #1b1b1b;
$black: #000000;
$transparent-black: rgba(8, 6, 6, 0.8);
$lt-gray: #9b9b9b;
$white-gray: #b1b1b1;
$dirty-white: #eaeaea;
$white: #ffffff;
$alert: #ffa023;
$confirm: #50da00;
$danger: #ff4423;

$title-white: #eaeaea;
$body-white: #c7c7c7;

$hover-transition-duration: 0.25s;
$header-height: 90px;

$grid-breakpoints: (
  xs: 425px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1520px,
);

$container-max-widths: (
  xs: 425px,
  sm: 576px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1420px,
);

// Typography

$font-size-base: 18px;
$font-weight-base: 400;
$font-line-height-base: 1;
$primary-font-family: "HarmonyOS Sans", sans-serif;
$secondary-font-family: "Orbitron", sans-serif;
$base-font-family: $primary-font-family;

$heading-sizes: (
  font-hero: 62px,
  h0: 120px,
  h1: 80px,
  h2: 45px,
  h3: 20px,
  h4: 16px,
  h5: 12px,
  p1: $font-size-base,
  p2: 16px,
  font-label: 22px,
);

$heading-line-heights: (
  font-hero: 1.35,
  h0: 1.35,
  h1: 1.35,
  h2: 1.35,
  h3: 1.35,
  h4: 1.35,
  h5: 1.35,
  p1: 1,
  p2: 1,
  font-label: 1,
);

$heading-weights: (
  font-hero: 400,
  h0: 300,
  h1: 700,
  h2: 500,
  h3: 700,
  h4: 400,
  h5: 400,
  p1: 400,
  p2: 400,
  font-label: 400,
);

$heading-font-families: (
  font-hero: #{meta.inspect($secondary-font-family)},
  h0: #{meta.inspect($secondary-font-family)},
  h1: #{meta.inspect($primary-font-family)},
  h2: #{meta.inspect($primary-font-family)},
  h3: #{meta.inspect($primary-font-family)},
  h4: #{meta.inspect($primary-font-family)},
  h5: #{meta.inspect($primary-font-family)},
  p1: #{meta.inspect($primary-font-family)},
  p2: #{meta.inspect($primary-font-family)},
  font-label: #{meta.inspect($primary-font-family)},
);
