@font-face {
  font-family: "Orbitron";
  src: url("./../assets/fonts/Orbitron/Orbitron-VariableFont_wght.ttf") format("truetype");
}
@font-face {
  font-family: "HarmonyOS_Sans";
  src: url("./../assets/fonts/HarmonyOS/HarmonyOS_Sans_Black.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "HarmonyOS_Sans";
  src: url("./../assets/fonts/HarmonyOS/HarmonyOS_Sans_Black_Italic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "HarmonyOS_Sans";
  src: url("./../assets/fonts/HarmonyOS/HarmonyOS_Sans_Bold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "HarmonyOS_Sans";
  src: url("./../assets/fonts/HarmonyOS/HarmonyOS_Sans_Bold_Italic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "HarmonyOS_Sans";
  src: url("./../assets/fonts/HarmonyOS/HarmonyOS_Sans_Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "HarmonyOS_Sans";
  src: url("./../assets/fonts/HarmonyOS/HarmonyOS_Sans_Medium_Italic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "HarmonyOS_Sans";
  src: url("./../assets/fonts/HarmonyOS/HarmonyOS_Sans_Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "HarmonyOS_Sans";
  src: url("./../assets/fonts/HarmonyOS/HarmonyOS_Sans_Regular_Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "HarmonyOS_Sans";
  src: url("./../assets/fonts/HarmonyOS/HarmonyOS_Sans_Light.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "HarmonyOS_Sans";
  src: url("./../assets/fonts/HarmonyOS/HarmonyOS_Sans_Light_Italic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: "HarmonyOS_Sans";
  src: url("./../assets/fonts/HarmonyOS/HarmonyOS_Sans_Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "HarmonyOS_Sans";
  src: url("./../assets/fonts/HarmonyOS/HarmonyOS_Sans_Thin_Italic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}
