// @use "variables.scss" as *;

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-style: normal;
  background-color: $background;

  font-family: $base-font-family;
  font-size: $font-size-base;
  font-weight: $font-weight-base;
  line-height: $font-line-height-base;
  font-kerning: auto;
  color: $default-text-color;
}

img {
  user-select: none;
  pointer-events: none;
  display: block;
}

::-moz-selection {
  /* Code for Firefox */
  background: $white;
  color: $black;
}

::selection {
  background: $white;
  color: $black;
}

body::-webkit-scrollbar {
  width: 0.5em;
  // width: 0em;
  // display: none;
}

body::-webkit-scrollbar-track {
  box-shadow: none;
  background: $gray;
  display: none;
}

body::-webkit-scrollbar-thumb {
  background: $black;
  border-radius: 12px;
  border: 1px solid $gray;
}

@for $i from 1 through 7 {
  $size-increment: 0.8rem;

  .gap-#{$i} {
    gap: calc($size-increment * $i * $i/3) !important;
  }
}

@for $i from 0 through 7 {
  $size-increment: 0.8rem;

  @each $key, $breakpoint in $grid-breakpoints {
    @media (min-width: $breakpoint) {
      .m-#{$key}-#{$i} {
        margin: calc($size-increment * $i * $i/3) !important;
      }
      .mt-#{$key}-#{$i} {
        margin-top: calc($size-increment * $i * $i/3) !important;
      }
      .ml-#{$key}-#{$i} {
        margin-left: calc($size-increment * $i * $i/3) !important;
      }
      .mr-#{$key}-#{$i} {
        margin-right: calc($size-increment * $i * $i/3) !important;
      }
      .mb-#{$key}-#{$i} {
        margin-bottom: calc($size-increment * $i * $i/3) !important;
      }

      .p-#{$key}-#{$i} {
        padding: calc($size-increment * $i * $i/3) !important;
      }
      .pt-#{$key}-#{$i} {
        padding-top: calc($size-increment * $i * $i/3) !important;
      }
      .pl-#{$key}-#{$i} {
        padding-left: calc($size-increment * $i * $i/3) !important;
      }
      .pr-#{$key}-#{$i} {
        padding-right: calc($size-increment * $i * $i/3) !important;
      }
      .pb-#{$key}-#{$i} {
        padding-bottom: calc($size-increment * $i * $i/3) !important;
      }
    }
  }

  .m-#{$i} {
    margin: calc($size-increment * $i * $i/3) !important;
  }
  .mt-#{$i} {
    margin-top: calc($size-increment * $i * $i/3) !important;
  }
  .ml-#{$i} {
    margin-left: calc($size-increment * $i * $i/3) !important;
  }
  .mr-#{$i} {
    margin-right: calc($size-increment * $i * $i/3) !important;
  }
  .mb-#{$i} {
    margin-bottom: calc($size-increment * $i * $i/3) !important;
  }

  .p-#{$i} {
    padding: calc($size-increment * $i * $i/3) !important;
  }
  .pt-#{$i} {
    padding-top: calc($size-increment * $i * $i/3) !important;
  }
  .pl-#{$i} {
    padding-left: calc($size-increment * $i * $i/3) !important;
  }
  .pr-#{$i} {
    padding-right: calc($size-increment * $i * $i/3) !important;
  }
  .pb-#{$i} {
    padding-bottom: calc($size-increment * $i * $i/3) !important;
  }
}

.normal-container {
  margin: auto;
  box-sizing: border-box;
  @each $key, $breakpoint in $grid-breakpoints {
    @media (min-width: $breakpoint) {
      max-width: map-get($container-max-widths, $key);
    }
  }
}

.big-container {
  margin: auto;
  box-sizing: border-box;
  @each $key, $breakpoint in $grid-breakpoints {
    @media (min-width: $breakpoint) {
      padding-left: 1rem;
      max-width: calc(map-get($grid-breakpoints, $key) - 2rem);
    }
  }
}

.small-container {
  margin: auto;
  box-sizing: border-box;
  @each $key, $breakpoint in $grid-breakpoints {
    @media (min-width: $breakpoint) {
      max-width: calc(map-get($container-max-widths, $key) - 8vw);
    }
  }
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.color-white {
  color: $white !important;
}

.color-black {
  color: $black !important;
}

.color-primary {
  color: $primary !important;
}

.color-purple {
  color: $purple !important;
}

.color-brown {
  color: $brown !important;
}

.text-overflow-ellipsis {
  text-overflow: ellipsis;
}

.overflow-hidden {
  overflow: hidden;
}

.text-nowrap {
  white-space: nowrap;
}

.p-relative {
  position: relative !important;
}

.d-flex {
  display: flex !important;
}

.d-block {
  display: block !important;
}

.d-none {
  display: none !important;
}

.select-none {
  user-select: none !important;
}

.flex-columns {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-center {
  align-items: center;
}

.transparent {
  opacity: 0.5;
}

.disabled {
  user-select: none;
  pointer-events: none;
  opacity: 0.5;
}

.soft-disabled {
  user-select: none;
  pointer-events: none;
  opacity: 1;
}

.w-100 {
  width: 100% !important;
}

.primary-gradient {
  background: linear-gradient(
    180deg,
    $primary-gradient-start 12.12%,
    $primary-gradient-mid 42.19%,
    $primary-gradient-end 104.49%
  );
}

a {
  text-decoration: none !important;
  color: $white;
  cursor: pointer;
}

.header-spacer {
  padding-top: $header-height;
}
