@import "./../../styles/variables.scss";

.external-cta-container {
  width: 100%;
}

.external-cta {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: auto;
  gap: 1rem;
  border-radius: 15px;
  border: 1px solid #232323;
  font-size: 14px;

  position: relative;
  padding: 1rem 1.5rem;
  z-index: 1;
  transition: background-color $hover-transition-duration ease-out;

  &:hover {
    background-color: #232323;
  }

  img {
    height: 15px;
  }
}

.color-default {
  .external-cta {
    border-color: #232323;
    &:hover {
      background-color: #232323;
    }
  }
}

.color-primary {
  .external-cta {
    border-color: $primary;
    &:hover {
      background-color: darken($primary, 25%);
    }
  }
}
