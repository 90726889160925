@import "./../../styles/variables.scss";

.header {
  position: fixed;
  top: 0;
  width: 100%;
  height: $header-height;
  padding: 3rem;
  box-sizing: border-box;
  z-index: 999;

  @media (width <= map-get($grid-breakpoints, lg)) {
    padding: 1rem;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1;
    background: linear-gradient(0deg, transparent, rgba($background, 50%) 20%, $background 100%);

    height: 150px;
    z-index: -1;
  }
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar-link {
  font-size: 16px;
  font-weight: 500;
  color: $white;

  &:hover {
    color: $primary;
  }
}

.header-section {
  width: 33%;
  @media (width <= map-get($grid-breakpoints, md)) {
    width: auto;
  }
}
