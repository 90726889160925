@import "./../../styles/variables.scss";

.nav-cta-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.nav-cta {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  gap: 1rem;
  border-radius: 15px;
  background: linear-gradient(
    180deg,
    $primary-gradient-start 11.12%,
    $primary-gradient-mid 42.19%,
    $primary-gradient-end 104.49%
  );

  position: relative;
  padding: 1rem 2.5rem;
  z-index: 1;
  transition: box-shadow $hover-transition-duration ease-out;
  box-shadow: 0px 0px 0px 0px rgba($primary, 80%);

  &:hover {
    box-shadow: 0px 0px 24px 0px rgba($primary, 80%);
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 14px;
    z-index: -1;
    background-color: $background;
    margin: 2px;
    height: calc(100% - 4px);
    width: calc(100% - 4px);
  }

  img {
    height: 30px;
  }
}
