@import "./../../styles/variables.scss";

.action-button-container {
  width: 100%;
}

.action-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  gap: 1rem;
  border-radius: 300px;
  border: 1px solid $primary;

  position: relative;
  padding: 2rem 3rem;
  z-index: 1;

  font-size: 25px;
  font-weight: 500;
  line-height: 29.3px;
  text-align: left;
  white-space: nowrap;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 300px;
    z-index: -1;
    background: linear-gradient(
      180deg,
      $primary-gradient-start 11.12%,
      $primary-gradient-mid 42.19%,
      $primary-gradient-end 104.49%
    );
    border: 1px solid #44d41e;
    box-shadow: inset 0px 4px 10.6px 0px rgb(255 255 255 / 41%);

    margin: 12px;
    height: calc(100% - 24px);
    width: calc(100% - 24px);
    transition: margin $hover-transition-duration ease-out, height $hover-transition-duration ease-out,
      width $hover-transition-duration ease-out, border-width $hover-transition-duration ease-out;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 300px;
    z-index: -1;
    background: linear-gradient(
      180deg,
      $primary-gradient-start 11.12%,
      $primary-gradient-mid 42.19%,
      $primary-gradient-end 104.49%
    );
    border: 1px solid #44d41e;
    box-shadow: inset 0px 4px 10.6px 0px rgb(255 255 255 / 41%);

    //
    // Variant 1
    //
    // height: 100%;
    // width: 100%;
    // opacity: 0;
    // transform: scale(0.5);
    // filter: blur(12px);
    // transition: opacity $hover-transition-duration ease-out, transform $hover-transition-duration ease-out;

    //
    // Variant 2
    //
    margin: 12px;
    height: calc(100% - 24px);
    width: calc(100% - 24px);
    transition: margin $hover-transition-duration ease-out, height $hover-transition-duration ease-out,
      width $hover-transition-duration ease-out;
  }

  &:hover {
    &::after {
      //
      // Variant 1
      //
      // opacity: 1;
      // transform: scale(1);

      //
      // Variant 2
      //
      margin: 0px;
      border-width: 0px;
      height: calc(100% - 0px);
      width: calc(100% - 0px);
    }
  }
}
